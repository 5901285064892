.nonClickable {
  pointer-events: none;
}

.addParticipantButton {
  color: white;
}

.titleIconBox {
  background-color: #f4f5f8;
}
.cardIconBox {
  background-color: #f4f5f8;
}
.meetingCardItemBorderLeft {
  border-left: 1px solid #C8C7CC;
}
.meetingCardItemBorderTop {
  border-top: 1px solid #C8C7CC;
}

.meetingCardItemBorderBottom {
  border-bottom: 1px solid #C8C7CC;
}

.screenTipCard {
  box-shadow: 0px 4px 10px gray;
}

.meetingCardDescription {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.screenFooterButton {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.swiper-pagination-bullet {
  background: var(--ion-color-medium);
  opacity: 0.5;
}

.swiper-pagination-bullet-active{
  background: var(--ion-color-dark);
  opacity: 1.0
}

/* this is needed so that MUI Avatar img covers the whole frame when used in IonSlide */
.swiper-slide img {
  height: 100%;
}

ion-slides {
  height: 100%;
}

.tipsTopicsIconBox {
  background-color: var(--ion-color-light);
}

.cardModal {
  --ion-background-color: transparent;
  --backdrop-opacity: 0;
}

@media (prefers-color-scheme: dark) {
  .addParticipantButton {
    color: black;
  }

  .titleIconBox {
    background-color: black;
  }
  .cardIconBox {
    background-color: #212123;
  }
  .meetingCardItemBorderLeft {
    border-left: 1px solid #404040;
  }
  .meetingCardItemBorderTop {
    border-top: 1px solid #404040;
  }
  .meetingCardItemBorderBottom {
    border-bottom: 1px solid #404040;
  }
  .screenTipCard {
    box-shadow: 0px 0px 
  }
  /* .screenFooterButton {
    background-color: black;
  } */
}

@media (min-width: 540px) {
  .appContainer {
    margin: auto;
    max-width: 540px;
    box-shadow: 0px 4px 10px gray;
  }

  /* overriding the max width of 500px for the ionPicker to match appContainer */
  .sc-ion-picker-ios-h {
    --max-width: 540px;
  }
}
