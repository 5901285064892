.CalendarALines {
  border-top: 1px solid #CECECE;
  border-right: 1px solid #CECECE;
}
.CalendarALastTimeSlot {
  border-top: 1px solid #CECECE;
}
.CalendarATimeLabel {
  color: #8D8D8E;
}
.CalendarABorderRight {
  border-right: 1px solid #CECECE;
}
.DaySchedyleAllDayTitleBorder {
  border-right: 1px solid #CECECE;
  border-left: 1px solid #CECECE;
}
.CalendarATimeSlotDisabled {
  background: repeating-linear-gradient(
    -45deg,
    #ECECEC,
    #ECECEC 8px,
    #CECECE 8px,
    #CECECE 9px
  );
  /* background-color: #E6E6E6; */
  border-top: 1px solid #CECECE;
  border-right: 1px solid #CECECE;
}
.CalendarATimeSlotPast {
  background: repeating-linear-gradient(
    -45deg,
    #ECECEC,
    #ECECEC 8px,
    #CECECE 8px,
    #CECECE 9px
  );
  opacity: 0.6;
}
.CalendarAAutoRange {
  border-color: #90EE90;
}

.CalendarANonActiveSlot {
  /* https://material-ui.com/customization/color/ */
  /* background-color: #FFECB3 */
  /* background-color: #e1f5fe */
  background-color: #e3f2fd
  /* background-color: #ffde73 yelloish brown */
}
.CalendarABusySlot {
  background-color: #989aa2
}
.CalendarACalendarEvent {
  background-color: rgba(206,206,206,0.5);
  border-color: #989aa2;
  backdrop-filter: blur(5px)
}
.CalendarAAutoSlot {
  background-color: #d6ffd6;
  opacity: 0.3;
}
.CalendarACalendarEventText {
  color: #5D5D5D;
}
.CalendarAEditBox {
  box-shadow: 0px 4px 4px #808289;
}
.CalendarADetailCardBox {
  box-shadow: 0px -2px 6px 2px #CECECE;
}
@media (prefers-color-scheme: dark) {

  .CalendarALines {
    border-top: 1px solid #212123;
    border-right: 1px solid #212123;
  }
  .CalendarALastTimeSlot {
    border-top: 1px solid #212123;
  }
  .CalendarATimeLabel {
    color: #8C8C90;
  }
  .CalendarABorderRight {
    border-right: 1px solid #212123;
  }
  .DaySchedyleAllDayTitleBorder {
    border-right: 1px solid #212123;
    border-left: 1px solid #212123;
  }
  .CalendarATimeSlotDisabled {
    background: repeating-linear-gradient(
      -45deg,
      #101010,
      #101010 8px,
      #383a3e 8px,
      #383a3e 9px
    );
    /* background-color: #383a3e; */
    border-top: 1px solid #212123;
    border-right: 1px solid #212123;
  }
  .CalendarATimeSlotPast {
    background: repeating-linear-gradient(
      -45deg,
      #101010,
      #101010 8px,
      #383a3e 8px,
      #383a3e 9px
    );
    opacity: 0.6;
  }
  .CalendarAAutoRange {
    border-color: #2E7D32;
  }
  .CalendarAAutoSlot {
    background-color: #3a613c;
    opacity: 0.3;
  }
  .CalendarANonActiveSlot {
    /* background-color: #E3C18A */
    /* background-color: #b3e5fc */
    background-color: #bbdefb
    /* background-color: #e7c966 yelloish brown */
  }
  .CalendarABusySlot {
    background-color: #383a3e
  }
  .CalendarACalendarEvent {
    background-color: rgba(33,33,35,0.5);
    border-color: #383a3e;
    backdrop-filter: blur(5px)
  }
  .CalendarACalendarEventText {
    color: darkgray;
  }
  .CalendarAEditBox {
    box-shadow: 0px 4px 4px #4f5157;
  }
  .CalendarADetailCardBox {
    box-shadow: 0px -2px 6px 2px #4f5157;
  }
}