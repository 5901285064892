
@media (prefers-color-scheme: dark) {

  .mdl-card {
    background-color: black;
  }

  .firebaseui-title {
    color: white;
  }

  .firebaseui-label {
    color: white;
  }

  .firebaseui-id-country-selector-code {
    color: white
  }

  .firebaseui-input {
    color: white;
    border-color: gray
  }

  .rc-anchor-light {
    background-color: black;
    color: white
  }
}