.CalendarDay__default,
.CalendarDay__default:hover  {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: white
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarDay__highlighted_calendar,
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover  {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__selected_span {
  background: var(--ion-color-secondary);
  color: white; 
  /* border: 1px solid var(--ion-color-success-tint); */
}
.CalendarDay__selected {
  background: var(--ion-color-primary);
  color: white;
}
.CalendarDay__selected:hover {
  background: var(--ion-color-primary);
  color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--ion-color-secondary-tint);
}


@media (prefers-color-scheme: dark) {

  .DayPicker {
    background: black;
  }
  .CalendarMonthGrid {
    background: black;
  }
  .CalendarMonth {
    background: black
  }
  .CalendarMonth_caption {
    color: lightgray;
  }
  .CalendarDay__default,
  .CalendarDay__default:hover  {
    border: 1px solid #212123;
    color: white;
    background: black
  }
  .CalendarDay__selected_span {
    background: var(--ion-color-secondary);
    color: white; 
    border: 0px;
  }
  .CalendarDay__selected_span:hover {
    border: 0px;
  }
  .CalendarDay__highlighted_calendar,
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover  {
    background: #ffce71;
    color: #484848
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: var(--ion-color-primary);
    color: white;
    border: 0px;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: var(--ion-color-secondary-tint);
  }
  .DayPickerNavigation_button__default {
    background-color: black;
    border: 1px solid #212123;
  }
  .DayPickerNavigation_svg__horizontal {
    fill: white;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: rgb(32, 32, 32);
    color: #636363
  }

  /* .DayPicker_weekHeader {
    color: lightgray;
  } */
}